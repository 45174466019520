@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .link-active {
    @apply border-b font-bold;
  }

  .link {
    @apply p-1 hover:cursor-pointer;
  }

  /**
  .project-card {
    @apply grid grid-cols-card p-2 transition ease-in duration-300 hover:scale-125;
  }
  **/
}